/**
 * @author [Sujan Basnet]
 * @email [basnetsujan95@gmail.com]
 * @create date 2019-03-25 16:53:15
 * @modify date 2019-03-25 16:53:15
 * @desc [description]
*/

@import '../../variables';
@import '../../main';

.divider {

    width: 1px;
    background-color: $text;
    height: 20px;
    font-family: 'Roboto' !important;

    &.small {
        height: 10px;
    }

}

.buttonsOrWrapper {
    @include flex;
    @include align-items(center);

    float: right;
}