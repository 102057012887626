@import url(https://fonts.googleapis.com/css?family=Roboto);
@import url(https://fonts.googleapis.com/css?family=Roboto);
@import url(https://fonts.googleapis.com/css?family=Roboto);
@import url(https://fonts.googleapis.com/css?family=Roboto);
body {
  margin: 0;
  padding: 0;
  font-family:"Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/**
 * @author [Sujan Basnet]
 * @email [basnetsujan95@gmail.com]
 * @create date 2019-02-14 12:39:46
 * @modify date 2019-02-14 12:39:46
 * @desc [description]
 */
/**
 * @author [Sujan Basnet]
 * @email [basnetsujan95@gmail.com]
 * @create date 2019-03-25 11:34:07
 * @modify date 2019-03-25 11:34:07
 * @desc [description]
*/
/**
 * @author [Sujan Basnet]
 * @email [basnetsujan95@gmail.com]
 * @create date 2019-03-25 11:40:57
 * @modify date 2019-03-25 11:40:57
 * @desc [description]
*/
/* CSS specific to iOS devices */
@supports (-webkit-overflow-scrolling: touch) {
  input {
    border-radius: 0;
    -webkit-border-radius: 0;
    -webkit-appearance: none; } }

.navbar_navbar__1KOvY {
  position: absolute;
  width: 100%;
  z-index: 2;
  background-color: transparent; }
  @media screen and (max-width: 850px) {
    .navbar_navbar__1KOvY {
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);
      background-color: white !important;
      position: static; } }
  .navbar_navbar__1KOvY > .navbar_navMenu__24TaD {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-around;
            justify-content: space-around;
    margin-right: 0 !important; }
    @media screen and (max-width: 850px) {
      .navbar_navbar__1KOvY > .navbar_navMenu__24TaD {
        -webkit-justify-content: space-between;
                justify-content: space-between; } }
    .navbar_navbar__1KOvY > .navbar_navMenu__24TaD::after {
      content: none !important; }
  .navbar_navbar__1KOvY .navbar_navItem__zRbM0 {
    color: rgba(0, 0, 0, 0.7) !important; }
    .navbar_navbar__1KOvY .navbar_navItem__zRbM0:hover {
      background-color: transparent !important; }
    .navbar_navbar__1KOvY .navbar_navItem__zRbM0.navbar_active__XNUTt.navbar_active__XNUTt.navbar_active__XNUTt.navbar_active__XNUTt, .navbar_navbar__1KOvY .navbar_navItem__zRbM0:hover {
      color: #DE6161 !important;
      background-color: transparent !important; }
    .navbar_navbar__1KOvY .navbar_navItem__zRbM0 > a {
      transition: color 0.025s linear;
      text-transform: uppercase;
      font-weight: 600;
      color: inherit; }

.navbar_active__XNUTt {
  color: #DE6161 !important; }

.navbar_verticalNavbar__SeoRC {
  position: absolute !important;
  z-index: 5;
  top: 60px;
  left: 0;
  width: 100vw !important;
  z-index: 1;
  padding-left: 5px;
  margin: 0 !important; }
  @media screen and (max-width: 850px) {
    .navbar_verticalNavbar__SeoRC {
      transition: max-height 0.425s ease-in-out;
      max-height: 0;
      min-height: 0 !important;
      overflow: hidden;
      background-color: white !important; }
      .navbar_verticalNavbar__SeoRC.navbar_verticalNavbar__SeoRC.navbar_verticalNavbar__SeoRC.navbar_verticalNavbar__SeoRC.navbar_verticalNavbar__SeoRC {
        box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.1); }
      .navbar_verticalNavbar__SeoRC.navbar_visible__3XKTl {
        max-height: 1000px; } }

.navbar_logo__3cvUE {
  height: 50px; }
  @media screen and (max-width: 380px) {
    .navbar_logo__3cvUE {
      height: 35px; } }

.navbar_barsWrapper__3gDaY {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  height: 100%; }
  .navbar_barsWrapper__3gDaY .navbar_bars__oTvfi {
    height: 25px;
    width: auto;
    margin-left: 23px !important; }
    @media screen and (max-width: 380px) {
      .navbar_barsWrapper__3gDaY .navbar_bars__oTvfi {
        height: 17px;
        margin-left: 18px; } }

/**
 * @author [Sujan Basnet]
 * @email [basnetsujan95@gmail.com]
 * @create date 2019-03-25 11:46:46
 * @modify date 2019-03-25 11:46:46
 * @desc [description]
*/
/**
 * @author [Sujan Basnet]
 * @email [basnetsujan95@gmail.com]
 * @create date 2019-03-25 11:34:07
 * @modify date 2019-03-25 11:34:07
 * @desc [description]
*/
.button_button__NoAdi {
  border-radius: 0 !important;
  min-width: 190px; }
  .button_button__NoAdi.button_primary__2yl2m {
    background-color: #DE6161 !important;
    color: #fff !important;
    border: 1px solid #DE6161 !important; }
  .button_button__NoAdi.button_secondary__2EwP- {
    background-color: #fff !important;
    border: 1px solid #DE6161 !important;
    color: #DE6161 !important; }
  .button_button__NoAdi.button_transparent__13bHo {
    background-color: transparent !important;
    min-width: 0; }
    @supports (-ms-ime-align: auto) {
      .button_button__NoAdi.button_transparent__13bHo {
        background-color: rgba(255, 255, 255, 0); } }
    .button_button__NoAdi.button_transparent__13bHo:hover {
      background-color: rgba(255, 255, 255, 0) !important; }
  .button_button__NoAdi.button_tiny__2oPFH {
    padding: 0 5px !important; }
  .button_button__NoAdi.button_small__3XL5l {
    padding: 10px !important;
    font-size: 10px !important; }
  @media screen and (max-width: 380px) {
    .button_button__NoAdi.button_reduce__32Hgt {
      font-size: 12px; } }
  @media screen and (max-width: 600px) {
    .button_button__NoAdi.button_responsive__1evBi {
      min-width: 120px; } }

/**
 * @author [Sujan Basnet]
 * @email [basnetsujan95@gmail.com]
 * @create date 2019-03-25 16:53:15
 * @modify date 2019-03-25 16:53:15
 * @desc [description]
*/
/**
 * @author [Sujan Basnet]
 * @email [basnetsujan95@gmail.com]
 * @create date 2019-03-25 11:34:07
 * @modify date 2019-03-25 11:34:07
 * @desc [description]
*/
/**
 * @author [Sujan Basnet]
 * @email [basnetsujan95@gmail.com]
 * @create date 2019-03-25 11:40:57
 * @modify date 2019-03-25 11:40:57
 * @desc [description]
*/
/* CSS specific to iOS devices */
@supports (-webkit-overflow-scrolling: touch) {
  input {
    border-radius: 0;
    -webkit-border-radius: 0;
    -webkit-appearance: none; } }

.buttonsor_divider__2RFwH {
  width: 1px;
  background-color: rgba(0, 0, 0, 0.7);
  height: 20px;
  font-family: 'Roboto' !important; }
  .buttonsor_divider__2RFwH.buttonsor_small__1t8OW {
    height: 10px; }

.buttonsor_buttonsOrWrapper__11lEB {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  float: right; }

/**
 * @author [Sujan Basnet]
 * @email [basnetsujan95@gmail.com]
 * @create date 2019-04-02 12:05:36
 * @modify date 2019-04-02 12:05:36
 * @desc [description]
*/
/**
 * @author [Sujan Basnet]
 * @email [basnetsujan95@gmail.com]
 * @create date 2019-03-25 11:34:07
 * @modify date 2019-03-25 11:34:07
 * @desc [description]
*/
/**
 * @author [Sujan Basnet]
 * @email [basnetsujan95@gmail.com]
 * @create date 2019-03-25 11:40:57
 * @modify date 2019-03-25 11:40:57
 * @desc [description]
*/
/* CSS specific to iOS devices */
@supports (-webkit-overflow-scrolling: touch) {
  input {
    border-radius: 0;
    -webkit-border-radius: 0;
    -webkit-appearance: none; } }

.bookappointment_modal__1bWFz {
  border-radius: 0 !important; }
  .bookappointment_modal__1bWFz .bookappointment_header__tt-T6 {
    color: rgba(0, 0, 0, 0.7);
    margin-bottom: 20px; }
  .bookappointment_modal__1bWFz .bookappointment_description__1mO4k {
    padding: 15px; }
    .bookappointment_modal__1bWFz .bookappointment_description__1mO4k .bookappointment_form__3O_GV {
      color: rgba(0, 0, 0, 0.7); }
      .bookappointment_modal__1bWFz .bookappointment_description__1mO4k .bookappointment_form__3O_GV label {
        color: inherit; }
  .bookappointment_modal__1bWFz .bookappointment_sideImage__1KNnD {
    background-image: url(/static/media/architectural-design-architecture-building-534247.f5c6464f.png);
    background-repeat: no-repeat;
    background-size: cover;
    color: red; }
  .bookappointment_modal__1bWFz .bookappointment_errorMsg__1SZcM {
    box-shadow: none;
    background-color: transparent;
    padding: 0;
    margin-top: -10px; }
    .bookappointment_modal__1bWFz .bookappointment_errorMsg__1SZcM p {
      color: #4c4c4c; }
  .bookappointment_modal__1bWFz .bookappointment_input__1hcbC input, .bookappointment_modal__1bWFz .bookappointment_input__1hcbC div {
    border-radius: 0 !important; }

