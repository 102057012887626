/**
 * @author [Sujan Basnet]
 * @email [basnetsujan95@gmail.com]
 * @create date 2019-04-02 12:05:36
 * @modify date 2019-04-02 12:05:36
 * @desc [description]
*/

@import '../../variables';
@import '../../main';

.modal {
    border-radius: 0 !important;

    .header {
        color: $text;
        margin-bottom: 20px;
    }

    .description {
        padding: 15px;

        .form {
            color: $text;

            label {
                color: inherit;
            }
        }

    }

    .sideImage {
        background-image: url('../../images/architectural-design-architecture-building-534247.png');
        background-repeat: no-repeat;
        background-size: cover;
        color: red;
    }

    .errorMsg {
        @include box-shadow(none);
        
        background-color: transparent;
        padding: 0;
        margin-top: -10px;

        p {
            color: #4c4c4c;
        }

    }

    .input {
        input, div {
            border-radius: 0 !important;
        }
    }

}