/**
 * @author [Sujan Basnet]
 * @email [basnetsujan95@gmail.com]
 * @create date 2019-03-25 11:34:07
 * @modify date 2019-03-25 11:34:07
 * @desc [description]
*/

// color
$primary: #DE6161;
$secodary: #0095DA;
$theme:#fff;
$text: rgba(0,0,0,0.7);
$stroke: rgba(0,0,0,0.1);
$overlay: rgba(253, 253, 253, 0.89);

// font
$font: 'Lato', sans-serif;
$normal-font-size: 16px;
$header-font-size: 24px;
$header-font-size-large: 35px;
// $card-name-font-size: 16px;
$card-name-font-size: 20px;

// navigation
$navBackground: transparent;
$fontWeight: bold;
